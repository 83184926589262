// Add a description here that will show up along the signup process (e.g.: informative or discount messages) -- TODO: this should be made dynamic and collected from the database standing to the chosen plan.
export const signupDescription = "";

export const signupPrices = [
  { label: '$0',  value: 0 },
  { label: '$5',  value: 5 },
  { label: '$10', value: 10 },
  { label: '$15', value: 15 },
  { label: '$25', value: 25 },
  { label: '$35', value: 35 },
  { label: '$45', value: 45 },
  { label: '$55', value: 55 },
  { label: '$65', value: 65 },
  { label: '$75', value: 75 },
  { label: '$85', value: 85 },
  { label: '$95', value: 95 },
  { label: '$105', value: 105 },
  { label: '$115', value: 115 },
  { label: '$125', value: 125 },
  { label: '$135', value: 135 },
  { label: '$145', value: 145 },
  { label: '$155', value: 155 },
  { label: '$165', value: 165 },
  { label: '$175', value: 175 },
  { label: '$185', value: 185 },
  { label: '$195', value: 185 },
  { label: '$250', value: 250 }
];

export const signupSelectedPrice = { label: '$5', value: 5 };

export const businessIndustries = [
  {
    "label": "accounting",
    "value": "Accounting"
  },
  {
    "label": "airlines_aviation",
    "value": "Airlines/Aviation"
  },
  {
    "label": "alternative_dispute_resolution",
    "value": "Alternative Dispute Resolution"
  },
  {
    "label": "alternative_medicine",
    "value": "Alternative Medicine"
  },
  {
    "label": "animation",
    "value": "Animation"
  },
  {
    "label": "apparel_fashion",
    "value": "Apparel & Fashion"
  },
  {
    "label": "architecture_planning",
    "value": "Architecture & Planning"
  },
  {
    "label": "arts_crafts",
    "value": "Arts & Crafts"
  },
  {
    "label": "automotive",
    "value": "Automotive"
  },
  {
    "label": "aviation_aerospace",
    "value": "Aviation & Aerospace"
  },
  {
    "label": "banking",
    "value": "Banking"
  },
  {
    "label": "biotechnology",
    "value": "Biotechnology"
  },
  {
    "label": "broadcast_media",
    "value": "Broadcast Media"
  },
  {
    "label": "building_materials",
    "value": "Building Materials"
  },
  {
    "label": "business_supplies_equipment",
    "value": "Business Supplies & Equipment"
  },
  {
    "label": "capital_markets",
    "value": "Capital Markets"
  },
  {
    "label": "chemicals",
    "value": "Chemicals"
  },
  {
    "label": "civic_social_organization",
    "value": "Civic & Social Organization"
  },
  {
    "label": "civil_engineering",
    "value": "Civil Engineering"
  },
  {
    "label": "commercial_real_estate",
    "value": "Commercial Real Estate"
  },
  {
    "label": "computer_network_security",
    "value": "Computer & Network Security"
  },
  {
    "label": "computer_games",
    "value": "Computer Games"
  },
  {
    "label": "computer_hardware",
    "value": "Computer Hardware"
  },
  {
    "label": "computer_networking",
    "value": "Computer Networking"
  },
  {
    "label": "computer_software",
    "value": "Computer Software"
  },
  {
    "label": "construction",
    "value": "Construction"
  },
  {
    "label": "consumer_electronics",
    "value": "Consumer Electronics"
  },
  {
    "label": "consumer_goods",
    "value": "Consumer Goods"
  },
  {
    "label": "consumer_services",
    "value": "Consumer Services"
  },
  {
    "label": "cosmetics",
    "value": "Cosmetics"
  },
  {
    "label": "dairy",
    "value": "Dairy"
  },
  {
    "label": "defense_space",
    "value": "Defense & Space"
  },
  {
    "label": "design",
    "value": "Design"
  },
  {
    "label": "education_management",
    "value": "Education Management"
  },
  {
    "label": "e_learning",
    "value": "E-learning"
  },
  {
    "label": "electrical_electronic_manufacturing",
    "value": "Electrical & Electronic Manufacturing"
  },
  {
    "label": "entertainment",
    "value": "Entertainment"
  },
  {
    "label": "environmental_services",
    "value": "Environmental Services"
  },
  {
    "label": "events_services",
    "value": "Events Services"
  },
  {
    "label": "executive_office",
    "value": "Executive Office"
  },
  {
    "label": "facilities_services",
    "value": "Facilities Services"
  },
  {
    "label": "farming",
    "value": "Farming"
  },
  {
    "label": "financial_services",
    "value": "Financial Services"
  },
  {
    "label": "fine_art",
    "value": "Fine Art"
  },
  {
    "label": "fishery",
    "value": "Fishery"
  },
  {
    "label": "food_beverages",
    "value": "Food & Beverages"
  },
  {
    "label": "food_production",
    "value": "Food Production"
  },
  {
    "label": "fundraising",
    "value": "Fundraising"
  },
  {
    "label": "furniture",
    "value": "Furniture"
  },
  {
    "label": "gambling_casinos",
    "value": "Gambling & Casinos"
  },
  {
    "label": "glass_ceramics_concrete",
    "value": "Glass, Ceramics & Concrete"
  },
  {
    "label": "government_administration",
    "value": "Government Administration"
  },
  {
    "label": "government_relations",
    "value": "Government Relations"
  },
  {
    "label": "graphic_design",
    "value": "Graphic Design"
  },
  {
    "label": "health_wellness_fitness",
    "value": "Health, Wellness & Fitness"
  },
  {
    "label": "higher_education",
    "value": "Higher Education"
  },
  {
    "label": "hospital_health_care",
    "value": "Hospital & Health Care"
  },
  {
    "label": "hospitality",
    "value": "Hospitality"
  },
  {
    "label": "human_resources",
    "value": "Human Resources"
  },
  {
    "label": "import_export",
    "value": "Import & Export"
  },
  {
    "label": "individual_family_services",
    "value": "Individual & Family Services"
  },
  {
    "label": "industrial_automation",
    "value": "Industrial Automation"
  },
  {
    "label": "information_services",
    "value": "Information Services"
  },
  {
    "label": "information_technology_services",
    "value": "Information Technology & Services"
  },
  {
    "label": "insurance",
    "value": "Insurance"
  },
  {
    "label": "international_affairs",
    "value": "International Affairs"
  },
  {
    "label": "international_trade_development",
    "value": "International Trade & Development"
  },
  {
    "label": "internet",
    "value": "Internet"
  },
  {
    "label": "investment_banking_venture",
    "value": "Investment Banking/Venture"
  },
  {
    "label": "investment_management",
    "value": "Investment Management"
  },
  {
    "label": "judiciary",
    "value": "Judiciary"
  },
  {
    "label": "law_enforcement",
    "value": "Law Enforcement"
  },
  {
    "label": "law_practice",
    "value": "Law Practice"
  },
  {
    "label": "legal_services",
    "value": "Legal Services"
  },
  {
    "label": "legislative_office",
    "value": "Legislative Office"
  },
  {
    "label": "leisure_travel",
    "value": "Leisure & Travel"
  },
  {
    "label": "libraries",
    "value": "Libraries"
  },
  {
    "label": "logistics_supply_chain",
    "value": "Logistics & Supply Chain"
  },
  {
    "label": "luxury_goods_jewelry",
    "value": "Luxury Goods & Jewelry"
  },
  {
    "label": "machinery",
    "value": "Machinery"
  },
  {
    "label": "management_consulting",
    "value": "Management Consulting"
  },
  {
    "label": "maritime",
    "value": "Maritime"
  },
  {
    "label": "marketing_advertising",
    "value": "Marketing & Advertising"
  },
  {
    "label": "market_research",
    "value": "Market Research"
  },
  {
    "label": "mechanical_or_industrial_engineering",
    "value": "Mechanical or Industrial Engineering"
  },
  {
    "label": "media_production",
    "value": "Media Production"
  },
  {
    "label": "medical_device",
    "value": "Medical Device"
  },
  {
    "label": "medical_practice",
    "value": "Medical Practice"
  },
  {
    "label": "mental_health_care",
    "value": "Mental Health Care"
  },
  {
    "label": "military",
    "value": "Military"
  },
  {
    "label": "mining_metals",
    "value": "Mining & Metals"
  },
  {
    "label": "motion_pictures_film",
    "value": "Motion Pictures & Film"
  },
  {
    "label": "museums_institutions",
    "value": "Museums & Institutions"
  },
  {
    "label": "music",
    "value": "Music"
  },
  {
    "label": "nanotechnology",
    "value": "Nanotechnology"
  },
  {
    "label": "newspapers",
    "value": "Newspapers"
  },
  {
    "label": "nonprofit_organization_management",
    "value": "Nonprofit Organization Management"
  },
  {
    "label": "oil_energy",
    "value": "Oil & Energy"
  },
  {
    "label": "online_publishing",
    "value": "Online Publishing"
  },
  {
    "label": "outsourcing_offshoring",
    "value": "Outsourcing/Offshoring"
  },
  {
    "label": "package_freight_delivery",
    "value": "Package/Freight Delivery"
  },
  {
    "label": "packaging_containers",
    "value": "Packaging & Containers"
  },
  {
    "label": "paper_forest_products",
    "value": "Paper & Forest Products"
  },
  {
    "label": "performing_arts",
    "value": "Performing Arts"
  },
  {
    "label": "pharmaceuticals",
    "value": "Pharmaceuticals"
  },
  {
    "label": "philanthropy",
    "value": "Philanthropy"
  },
  {
    "label": "photography",
    "value": "Photography"
  },
  {
    "label": "plastics",
    "value": "Plastics"
  },
  {
    "label": "political_organization",
    "value": "Political Organization"
  },
  {
    "label": "primary_secondary",
    "value": "Primary/Secondary"
  },
  {
    "label": "printing",
    "value": "Printing"
  },
  {
    "label": "professional_training",
    "value": "Professional Training"
  },
  {
    "label": "program_development",
    "value": "Program Development"
  },
  {
    "label": "public_policy",
    "value": "Public Policy"
  },
  {
    "label": "public_relations",
    "value": "Public Relations"
  },
  {
    "label": "public_safety",
    "value": "Public Safety"
  },
  {
    "label": "publishing",
    "value": "Publishing"
  },
  {
    "label": "railroad_manufacture",
    "value": "Railroad Manufacture"
  },
  {
    "label": "ranching",
    "value": "Ranching"
  },
  {
    "label": "real_estate",
    "value": "Real Estate"
  },
  {
    "label": "recreational",
    "value": "Recreational"
  },
  {
    "label": "facilities_services",
    "value": "Facilities & Services"
  },
  {
    "label": "religious_institutions",
    "value": "Religious Institutions"
  },
  {
    "label": "renewables_environment",
    "value": "Renewables & Environment"
  },
  {
    "label": "research",
    "value": "Research"
  },
  {
    "label": "restaurants",
    "value": "Restaurants"
  },
  {
    "label": "retail",
    "value": "Retail"
  },
  {
    "label": "security_investigations",
    "value": "Security & Investigations"
  },
  {
    "label": "semiconductors",
    "value": "Semiconductors"
  },
  {
    "label": "shipbuilding",
    "value": "Shipbuilding"
  },
  {
    "label": "sporting_goods",
    "value": "Sporting Goods"
  },
  {
    "label": "sports",
    "value": "Sports"
  },
  {
    "label": "staffing_recruiting",
    "value": "Staffing & Recruiting"
  },
  {
    "label": "supermarkets",
    "value": "Supermarkets"
  },
  {
    "label": "telecommunications",
    "value": "Telecommunications"
  },
  {
    "label": "textiles",
    "value": "Textiles"
  },
  {
    "label": "think_tanks",
    "value": "Think Tanks"
  },
  {
    "label": "tobacco",
    "value": "Tobacco"
  },
  {
    "label": "translation_localization",
    "value": "Translation & Localization"
  },
  {
    "label": "transportation_trucking_railroad",
    "value": "Transportation/Trucking/Railroad"
  },
  {
    "label": "utilities",
    "value": "Utilities"
  },
  {
    "label": "venture_capital",
    "value": "Venture Capital"
  },
  {
    "label": "veterinary",
    "value": "Veterinary"
  },
  {
    "label": "warehousing",
    "value": "Warehousing"
  },
  {
    "label": "wholesale",
    "value": "Wholesale"
  },
  {
    "label": "wine_spirits",
    "value": "Wine & Spirits"
  },
  {
    "label": "wireless",
    "value": "Wireless"
  },
  {
    "label": "writing_editing",
    "value": "Writing & Editing"
  }
];